import React from "react";
var d = new Date();
var currYear = d.getFullYear();

function Footer() {
  return (
    <React.StrictMode>
      <div className="copyright-details">
        <div className="about-social-icon text-center">
          <ul className="about-social">

            <li className="wow fadeIn" data-wow-delay=".4s">
            <a
                              href="https://wa.me/7068069067?text=Hello"
                              target="newtab"
                            >
                              <i
                                className="fa fa-whatsapp"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <a
                              href="https://www.snapchat.com/add/shubhzzz.x"
                              target="newtab"
                            >
                              <i
                                className="fa fa-snapchat"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <a
                              href="https://www.instagram.com/shubhzzz.x/"
                              target="newtab"
                            >
                              <i
                                className="fa fa-instagram"
                                aria-hidden="true"
                              ></i>
                            </a>
            </li>
          </ul>
        </div>
        <div className="copyright">
          <h3>&copy; {currYear}, Made with ❤️ by Shubhzzz.</h3>
        </div>
        
      </div>
    </React.StrictMode>
  );
}

export default Footer;
