import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Home from "./Pages/Home";

function App() {
  return (
    <React.StrictMode>
      <Router>
        <Header />
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        <Footer />
      </Router>
    </React.StrictMode>
  );
}

export default App;
